import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import CheckoutBegin from "../components/checkout-begin"

const Product = ({ data }) => (
  <Layout>
    <SEO title={data.shopifyProduct.title} description={data.shopifyProduct.description.split('.')[0].replace(/^(?:description ?)/i, '')} />
    <div css={css`
        padding: 12px;

        @media (min-width: 648px) {
          padding: 12px 0;
        }
    `}>
        <h1
          css={css`
            font-size: 28px;
            line-height: 32px;
            margin-bottom: 13px;
            margin-top: 2px;
            text-transform: uppercase;
          `} 
        >
            {data.shopifyProduct.title}
        </h1>
        <div
            css={css`
                font-size: 18px;
                line-height: 28px;
                margin-bottom: 0;

                p {
                  margin-bottom: 4px;
                  &:last-child {
                      margin-bottom: -6px;
                  }
                }

                p.legal {
                    margin-top: 14px;
                    font-size: 16px;
                    line-height: 16px;
                    &:last-child {
                        margin-bottom: -4px;
                    }
                }
            `}
            dangerouslySetInnerHTML={{__html: data.shopifyProduct.descriptionHtml}}
        />
        <div css={css`
            margin-top: 28px;
            // display: flex;
            // justify-content: space-between;
            // align-items: baseline;
        `}>
            {/* <div css={css`
                font-family: Helvetica;
                font-weight: 500;
                font-size: 24px;
            `}>
                $99.99
            </div> */}
            <CheckoutBegin product={data.shopifyProduct} preorder={data.shopifyProduct.productType === 'preorder'}/>
        </div>
    </div>
    {data.shopifyProduct.images.map(image => (
        <Img
            key={image.id}
            fluid={image.localFile.childImageSharp.fluid}
            css={css`
                & + * {
                    margin-top: 24px;
                }
            `}
        />
    ))}
  </Layout>
)

export default Product

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      description
      descriptionHtml
      productType
      variants {
        id
        price
        selectedOptions {
          name
          value
        }
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      images {
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`